/***
 Timeline
***/
.timeline.wrap {
	width: 100%;
}
.headline {
	text-transform: uppercase;
	letter-spacing: 2px;
	font-family: "Domine", serif;
	font-size: 2.2rem;
	display:inline-block;
}
.theme {
	font-family: "helvetica";
	font-size: 50px;
	color: #333333;
	opacity: 0.6;
	font-weight: 100;
	padding-right: 3rem;
	display: inline-block;
	margin-bottom: 0;
}
@media screen and (max-width: 768px) {
	.theme {
		font-size: 2em;
		padding-right: 1rem;
	}
	.headline {
		font-size: 1.5em;
		border-bottom: 1px solid #333;
	}
}
.marker:nth-of-type(1) {
	margin-top: 400px;
}
.marker {
	padding: 75px 40px 400px 40px;
	margin-left: 15%;
	margin-right: 15%;
}
@media screen and (max-width: 768px) {
	.marker {
		padding: 0px 0px 200px 0px;
	}
}
.blurb {
	margin-bottom: 30px;
	font-size: 1.3em;
	color: #333;
}
.hero {
	height: 500px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	align-items: center;
	align-content: center;
	font-size: 2.0vw;
	padding: 5px 50px 50px 50px;
}
@media screen and (max-width: 768px) {
	.hero {
		font-size: 3.0vw;
	}
}
.hero-text {
	width: 80%;
	color: white;
}
.site-title {
	opacity: 1.0;
	display: inline-block;
	padding-left: 12px;
  padding-right: 12px;
	transition: 1s linear all;
}
.knew {
	font-size: 7rem;
	font-family: "Domine", serif;
	font-weight: bold;
	text-align: center;
	padding-top: 100px;
	margin: 0;
	color: white;
}
.knew a {
	text-decoration: none;
	color: white;
}
@media screen and (max-width: 768px) {
	.knew {
		font-size: 4rem;
	}
}
#top {
	background-color: #333333;
	color: rgba(255, 255, 255, 1);
	opacity: 1;
	position: relative;
	min-height: 800px;
	height: 100vh;
	padding-bottom: 50px;
	background-image: url("/assets/images/oil1.jpg");
	background-size: cover;
	background-position: center center;
}
@media screen and (max-width: 736px) {
	#top {
		height: 660px;
	}
}
.take-action-button {
	margin-bottom: 40px;
}
.timeline-content {
	min-height: 200vh;
	background: rgba(255, 255, 255, 1);
	box-shadow: 10px 5px 50px rgba(255,255,255,1);
}
.marker:last-of-type {
	padding-bottom: 50px;
}
.document {
	display: block;
	padding: 7px 10px;
	text-transform: uppercase;
	font-family: "helvetica", sans-serif;
	letter-spacing: 2px;
	font-weight: bold;
	font-size: 10px;
	color: #646464;
	cursor: pointer;
	padding-top: 5px;
}
.timeline-toggle-button {
	display: block;
	text-transform: uppercase;
	background: #e2e2e2;
	border: 0;
	line-height: 2.5em;
	padding: 0 0 0 1em;
	outline: none;
	text-decoration: none;
	color: #666;
	margin-left: auto;
	margin-right: auto;
}
.timeline-toggle-button:hover {
	color: white;
	background: #486c70;
}
.timeline-toggle-arrow {
  display: inline-block;
  line-height: 2.5em;
  text-align: center;
  background: #403845;
  color: white;
  font-size: 1em;
  width: 2.5em;
  transition: margin 200ms;
  margin-left: .75em;
}
.timeline-toggle-arrow:hover {
	margin-left: 1.25em;
	background-color: darken(#333,5%);
}
.pi {
	background-color: #486c70;
}
.ci {
	background-color: #6e483c;
}
.ihs {
	background-color: #403845;
}
.ps {
	background-color: #505a43;
}
.dark {
  background-color: #333333;
  color: white;
  border: 1px solid #333333;
  border-radius: 1%;
}
.arrow {
	fill: white;
}

.footnotes {
	margin-top: 30px;
	padding-top: 30px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
	.footnotes {
		display: block;
	}
}
.footnotes::before {
	content: "";
	border-top: 1px solid #CCC;
	width: 25%;
	height: 1px;
	position: absolute;
	top: 0;
}
.arrow_up {
	display: block;
	position: relative;
	transform: rotate(180deg);
	left: 0;
	right: 0;
	height: 45px;
	width: 45px;
	margin: 60px auto;
	border-radius: 45px;
}
.arrow_down {
	display: block;
	height: 45px;
	width: 45px;
	margin: 0 auto;
	border-radius: 45px;
}

/* laptop screens */
@media only screen
	and (max-width: 1440px) {
		.arrow_down.hero_arrow {
			margin: -100px auto 0 auto;
		}
	}
@media only screen
	and (max-width: 1140px) {
		.arrow_down.hero_arrow {
			margin: -160px auto 0 auto;
		}
	}

/* ipad vertical */
@media only screen
	and (max-width: 768px) {
		.arrow_down.hero_arrow {
			margin: -40px auto 0 auto;
		}
	}

/* ipad landscape */
@media only screen
	and (min-device-width: 481px)
	and (max-device-width: 1024px)
	and (orientation:landscape) {
		.arrow_down {
			bottom: -40px;
		}
	}

/* vertical iphone 5 */
@media only screen
	and (max-width: 320px) {
		.arrow_down.hero_arrow {
			margin: -40px auto 0 auto;
		}
	}


.scroll-btn {
	position: absolute;
	left: 0;
	width: 1em;
	height: 1em;
	border: 0.2em solid #fff;
	border-left: none;
	border-bottom: none;
	cursor: pointer;
	transform-origin: 50% 50%;
	transition: border-color 0.3s;
}
&.up {
	top: -1.6em;
	transform: rotate(-45deg);
}
&.down {
	bottom: -1.2em;
	transform: rotate(135deg);
}
.nav {
	position: relative;
}
.nav a {
	display: inline-block;
	float: left;
	margin-bottom: 3px;
	font-weight: bold;
	margin-top: 20px;

	&:hover {opacity: .6}
}

.slide-nav {
	font-family: "helvetica", sans-serif;
	font-weight: normal;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-size: 12px;
	color: #646464;
	padding: 5px 10px;
	pointer:cursor;
}
.slide-nav:hover {
	opacity: .6;
}

.slide-nav  > i {
	position: absolute;
	left: -15px;
}
.marker i.fa {
	padding-right: 7px;
	font-size: 14px;
}
.sidecar {
	position: absolute;
	left: -100px;
	width: 1em;
	height: 1em;
	border-left: none;
	border-bottom: none;
	cursor: pointer;
	transform-origin: 50% 50%;
	transition: border-color 0.3s;
}
.indicator {
	display: inline-block;
	right: 2.7em;
	padding: 0.3em;
  background: grey;
	opacity: 1.0;
	cursor: pointer;
}

@media screen and (min-width: 1620px) {
		.indicator {
			right: 3.5em;
		}
}

.indicator-passed-add,
.indicator-passed-remove,
.indicator-active-add,
.indicator-active-remove {
	transition: all linear 0.5s;
}
.indicator-passed,
.indicator-passed-add.indicator-passed-add-active {
	opacity: 0.5;
	position: fixed;
	bottom: 40%;
}
.indicator-upcoming,
.indicator-upcoming-add.indicator-upcoming-add-active {
	position: fixed;
	bottom: 15%;
}
.indicator-active,
.indicator-active-add.indicator-active-add-active {
	padding: 0.7em;
}
.indicator-active, .indicator:hover {
	background: #333;
}
.indicator-tooltip {
  position: absolute;
  right: 2em;
  width: 0;
  margin-top: -1em;
  background-color: #806973;
  color: #FFF;
  white-space: nowrap;
  border-radius: 3px;
  overflow: hidden;
  z-index: -1;
}
.indicator-tooltip:after {
  display: block;
  content: '';
  position: absolute;
  right: -0.9em;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -0.4em;
  border: 0.4em solid transparent;
  border-left: 0.5em solid #806973;
}
.indicator:hover .indicator-tooltip {
  width: auto;
  padding: 0.5em;
  overflow: visible;
  z-index: 1;
}
.smoke-stack {
	position: fixed;
	width: 10%;
	bottom: 0;
	right: 2em;
	z-index: 2;
}
.smoke-stack path {
	fill: grey;
}
.cigs {
	position: fixed;
	width: 10%;
	bottom: -10px;
	right: -.5em;
	z-index: 2;
	fill: grey;
}
@media screen and (max-width: 1080px) {
	.smoke-stack, .cigs {
		display: none;
	}
	.indicator {
		display: none;
	}
}

/***
 Breakpoints
***/
@media screen and (max-width: 640px) {
  .hero {
    font-size: 100%;
		padding: 0;
  }
	.knew {
		font-size: 2rem;
		padding-top: 100px;
	}
	.nav {
		display: none;
	}
	.marker {
		padding: 0px 0px 200px 0px;
		margin-left: 5%;
		margin-right: 5%;
	}
	.document {
		float: none;
		text-align: center;
		padding: 15px 10px;
		margin-top: 30px;
	}
	.contact {
		padding-top: 20px;
	}
	.arrow_up {
		bottom: 100px;
	}
}
